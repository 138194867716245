<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header>
      <h6>{{ description }}</h6>
    </ion-list-header>
    <ion-list>
      <ion-item
        lines="none"
        v-for="item in resources.data"
        :key="item.id"
        :ref="`items-${item.id}`"
      >
        <ion-card class="full-width">
          <ion-item lines="none" class="ion-no-padding">
            <ion-card-header>
              <ion-card-title>Section: {{ item.name }} </ion-card-title>
            </ion-card-header>
          </ion-item>
          <ion-card-content>
            Started by:
            <p>{{ startedText(item) }}</p>
            <br />
            Completed by:
            <p>{{ completedText(item) }}</p>
          </ion-card-content>
        </ion-card>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No sections found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    description: {
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {

    };
  },
  methods: {
    startedText(item) {
      const progression = item.chapter_progressions;
      if (!Array.isArray(progression) || progression.length < 1) {
        return "Not Started.";
      }

      const started_by = progression[0].started_by
        ? progression[0].started_by
        : false;
      if (!started_by) {
        return "Not Started.";
      }

      const date = moment(item.started_at).format("MMM Do YYYY");

      return `${started_by.user.user_detail.name}, ${date}`;
    },

    completedText(item) {
      const progression = item.chapter_progressions;
      if (!Array.isArray(progression) || progression.length < 1) {
        return "Not Completed";
      }

      const completed_by = progression[0].completed_by
        ? progression[0].completed_by
        : false;
      if (!completed_by) {
        return "Not Started.";
      }
      const date = moment(item.completed_at).format("MMM Do YYYY");

      return `${completed_by.user.user_detail.name}, ${date}`;
    },
  },
};
</script>
